@import "./login/login";
@import "./dashboard/header/header";
@import "./dashboard/nav/nav";

.layout {
  display: flex;
  align-items: center;
  width: 100vw;

  height: 100%;
  flex-direction: column;
  overflow: hidden;

  &_down {
    height: 100%;
    width: 100vw;
    display: flex;
    justify-content: start;
  }
}

.main {
  overflow-y: scroll;
  width: 100%;
  height: calc(#{100vh} - #{55px});

  &::-webkit-scrollbar {
    width: 0 !important;
    display: none !important;
  }
}
