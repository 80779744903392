.exercise {
  display: flex;
  margin-bottom: 3rem;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  gap: 18px;
  align-items: flex-end;
  border: 1px solid $color-9;
  border-radius: 7px;
&:last-child{
  margin-bottom: 12rem;
}
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px;
    width: 100%;
    background: $color-4;
    border-radius: 7px;
    @include mq($correction_breakpoint, max) {
      flex-direction: column;
    }
    h3 {
      font-weight: 600;
      font-size: 2rem;
      color: $color-2;
    }
  }
}
