.switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem;
  gap: 1rem;
  width: 80%;

  background: $color-12;
  border-radius: 4rem;

  .selected {
    background: $color-2;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 5.4rem;
    background: transparent;
    border-radius: 3.125rem;
    border: none;
    cursor: pointer;
    margin-bottom: 0;

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      color: $color-0;
    }
  }
}
