.forget-password {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: $invitation_width;
  @include mq($login_breakpoint, max) {
    width: $invitation_width-2;
  }

  gap: 2rem;
  &_desc {
    color: $color-11;
    font-size: 1.7rem;
    font-weight: 500;
  }
  & > div {
    gap: 2rem;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
.MuiAlert-standardError {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-14;
  border-radius: 8px;
  color: $color-13;
}
.css-1t9pz9x{
  transform: scale(1.2);
}