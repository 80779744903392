.results {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  @include mq($subject_result_breakpoint, max) {
    display: flex;
    flex-direction: column;
  }
  .subject {
    flex: 1;
    max-width: 360px;
  }
}
