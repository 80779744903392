.login-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  direction: initial;
  width: 85%;
  text-align: center;

  max-width: 670px;

  &-container {
    gap: 9rem;
  }

  &_title {
    font-weight: 600;
    font-size: 4.5rem;
    line-height: 68px;
    color: $color-2;
  }

  &>div {
    width: 100%;
  }

  &_submit {
    background-color: $color-2;
  }
}

a,
.resend-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 24px;
  color: $color-2;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
  }

  &-disabled {
    opacity: 0.5;
    cursor: wait;

    &:hover {
      opacity: 0.2;
    }
  }
}

.no-account {
  &-container {
    direction: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}