@import "./ContentHeader/contentHeader";
@import "./ContentHeader/contentHeaderBreakPoint";
@import "./button/button";
@import "./switcher/switcher";
@import "./modal/modal";
@import "./Subject/subject";
@import "./leftIllustration/leftIllustration";
@import "./hook-form/hook-form";
@import "../assets/styles/abstracts/colors";
@import "./AuthCheckbox/AuthCheckbox";

.navSection {
   display: flex;
   align-items: center;
   gap: 1.5rem;

   margin: 1.4rem 0 0 2.4rem;

   &_text {
      color: $color-12;
      font-size: 2rem;
      font-style: normal;
      font-weight: 400;
   }
}