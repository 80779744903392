$login_breakpoint: 900px;
$sidebar-width-screen-breakpoint: 500px;
$contentHeader_breakpoint_large_screen: 1200px;
$contentHeader_breakpoint_medium_screen: 1000px;
$contentHeader_breakpoint_small_screen: 900px;
$sign_up_wrap: 600px;
$dashboard_large_screen: 1460px;
$stats_small_screen: 1300px;
$verification_input: 1000px;
$font-size: 570px;
$font-size-2: 313px;
$verification_input-2: 900px;
$invitation_width: 60%;
$invitation_width-2: 90%;
$results_subjects_breakpoint: 815px;
$subject_result_breakpoint: 388px;
$bulletin_exams_breakpoint: 640px;
$correction_breakpoint: 600px;
$header_breakpoint: 460px;
