.invitation-form {
  &_title {
    display: flex;
    flex-direction: column;
    width: $invitation_width;
    @include mq($login_breakpoint, max) {
      width: $invitation_width-2;
    }
    gap: 3rem;
  }
  &_desc {
    align-self: flex-start;
    color: $color-2;
    font-weight: 600;
    font-size: 2rem;
    line-height: 3.6rem;
  }
  &_teacher_card {
    width: 100%;
    background-color: $color-4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    &-div {
      width: $invitation_width;
      @include mq($login_breakpoint, max) {
        width: $invitation_width-2;
      }
      gap: 1.3rem;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }
    &-invited {
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 3rem;

      color: $color-11;
    }
    &-desc {
      display: flex;
      gap: 2rem;
      justify-content: center;
      align-items: center;
      .teacher {
        &_name {
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 3rem;
          display: flex;
          align-items: center;
          color: $color-10;
        }
        &_img {
          align-self: flex-start;
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
        &_det {
          display: flex;
          flex-direction: column;
          gap: 0.1rem;
        }

        &_desc {
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 2.8rem;

          color: $color-11;
        }
        &_p {
          font-size: 1.4rem;
          font-weight: 500;
        }
      }
    }
  }
}
.invitation_mail {
  width: $invitation_width;
  @include mq($login_breakpoint, max) {
    width: $invitation_width-2;
  }
  display: flex;
  flex-direction: column;
  &-title {
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 2.5rem;
    margin-top: 20px;
    color: $color-11;
  }
  &-content {
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 2.5;

    color: $color-11;
  }
}

.invitation-top{
  position: absolute;
  top: 0;
  right: 0;
  img{
    position:absolute;
    top: 0 ;
    right: 0
  };
  &_blue{
    z-index: 99;
  }
}

.password-info{
  display: flex;
  align-items: center;
  align-self: start;
  gap: 7px;
  height: fit-content;
  margin: 0 !important;
  p{
    color: $color-11;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
}