.section {
  &-text{
    color: #727272;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;

    @include mq(900px, max) {
      font-size: 1.7rem;
    }

    @include mq(600px, max) {
      font-size: 1.4rem;
    }
  }
  &-title {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    white-space: nowrap;

    @include mq(900px, max) {
      font-size: 1.5rem;
    }

    @include mq(600px, max) {
      font-size: 1.15rem;
    }

    @include mq(500px, max) {
      font-size: 1rem;
    }
  }

  &-img {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.Stats {
  margin-top: 3rem;

  &-content {
    gap: 5rem;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: start;
  }
}
