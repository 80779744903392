.grade {
  display: flex;
  padding: 1rem 2rem;
  border-radius: 25px;
  font-size: 3rem;
  white-space: nowrap;
  justify-content: center;
  &-total {
    align-self: flex-end;
    font-size: 1.8rem;
    &-exercice {
      align-self: center;
    }
  }
  @include mq($correction_breakpoint, max) {
    width: 100%;
  }
}
.exercice-grade {
  font-weight: 500;
  font-size: 2rem;
  gap: 0.2rem;
  color: white;
  display: flex;
  align-items: center;
  padding: 1rem 1.6rem;
  border-radius: 5px;
  background-color: $color-12;
}
