.login-layout_illustration {
  direction: initial;
}
.sign_illustration {
  width: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  h1 {
    color: $color-10;
    font-family: Poppins;
    font-size: 34px;
    font-weight: 600;
    line-height: 59px;
    letter-spacing: 0em;
    text-align: center;
    .last-word {
      color: $color-2;
    }
  }
  svg {
    margin-bottom: 3rem;
  }
}
