@import "./components/Update/update";
@import "./components/Password/password";
@import "../../assets/styles/abstracts/colors";

.update-picture {
  .ant-upload-picture-circle-wrapper {
    width: unset;
  }
  &_image{
    position: relative;
    height: fit-content;
  }
  &_container {
    width: 100%;
    display: flex;
    height: fit-content;
    gap: 2rem;
    flex-direction: column;
    box-shadow: 0px 0px 15px 0px rgba(210, 209, 209, 0.6);
    border-radius: 1.5rem;
    padding: 5rem 2.5rem;
    flex: 1.5
  }

  width:100%;
  gap: 1rem;
  align-items: center;
  display: flex;
  flex-direction: row;

  &-det {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;

    &-description {
      color: $color-13;
      font-family: Poppins;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
    }

    &-title {
      color: $color-11;
      font-family: Poppins;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 500;
    }
  }

  &_text {
    color: $color-2;
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
  }

  &_title {
    color: $color-11;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
  }

  &_value {
    color: $color-11;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
  }

  &_box {
    border-radius: 5px;
    border-left: 4px solid var(--12, #99C6D3);
    height: 5.8rem;
    padding: 0 0 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: .5rem;
  }
}

.profile {
  display: flex;
  flex-direction: row;
  gap: 5rem;
  padding: 5rem;
  @include mq(1160, max) {
    flex-direction: column;   
  }
}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item{
  border: 2px dashed #1B8392 ;
}

.plusCircle{
  position: absolute;
  bottom: 0.5rem;
  right: 1.7rem;
}