.exams_stats {
   display: flex;
   justify-content: center;
   gap: 1rem;
   align-items: center;
   flex-wrap: wrap;
   padding: 0 1rem;

   .pie-div {
      max-height: 230px;

      & div[data-chart-source-type='G2Plot'] {
         margin-top: -9rem;
      }
   }

   &_pallette {
      display: flex;
      align-items: start;
      justify-content: start;
      flex-wrap: wrap;
      gap: 1rem;

      @include mq(387px, max) {
         margin-left: 35px;
      }

      &-element {
         display: flex;
         gap: 1.3rem;
         justify-content: center;
         align-items: center;

         @include mq(470px, max) {
            flex-basis: 48%;
            max-width: 65%;
         }

         @include mq(387px, max) {
            flex-basis: 33%;
            max-width: 65%;
         }
      }

      &_el {
         display: flex;
         flex-direction: column;
         gap: 0.5rem;

         &>* {
            white-space: nowrap;
         }
      }

      &_type {
         font-weight: 400;
         font-size: 15px;
         line-height: 28px;

         display: flex;
         align-items: center;
      }

      &_value {
         font-family: 'Poppins';
         font-style: normal;
         font-weight: 500;
         font-size: 15px;
         line-height: 36px;
         display: flex;
         align-items: center;
      }

      &-color {
         width: 5.08px;
         height: 56px;
         border-radius: 126.893px;
      }
   }
}

.examen-title{
   position: absolute;
   bottom: 90px;
   right: 40%;
   color: #727272;
   font-size: 15.814px;
}
.g2-html-annotation{
   transform: translate(-50%, -35px); 
}