.text_field_container{
  display: flex;
    padding: 11px 24px;;
  
    flex-direction: column;

  
    .label {
      display: flex;
      font-size: 1.8rem;
      font-weight: 400;
      color: #999999;
  
      .required {
        color: $color-13;
  
  
      }
    }
  
    .input_container {
      input {
        border-radius: 12px;
        border: 1px solid #E4E4E4;
        width: 100%;
        padding: 1.5rem;
      }
    }
  }
  