.header {
  padding: 0.5rem 2rem;
  width: 100%;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-4;
  height: 66px;
  &_first {
    justify-content: space-between;
    display: flex;
    width: 246px;
  }
  &_end {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}
.user {
  &-header {
    padding: 1rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: 1rem;
    cursor: pointer;
  }

  &-name {
    color: #1b8392;
    font-size: 1.5rem;
    font-weight: 600;
  }

  &-email {
    color: #99c6d3;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  &-arrow {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.menu{
  &-item{
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
    justify-content: flex-end;
    align-items: center;
  }
  &-logout{
    width: 15px;
    height:16px;
  }
}
.profile-popup{
  display: flex;
  flex-direction: column;
  align-items:  flex-start;
  justify-content: flex-start;

}
.profile-btn{

    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: start;
    gap: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    img{
      width: 20px;
      height: 20px;
    }
}