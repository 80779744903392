.input{
    width: 100%;
 display: flex;
 align-items: flex-start;
 justify-content: flex-start;
 flex-direction: column;
 gap: 10px;
 &-label{
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
     color: $color-11;
    }

}
.red-star{
    font-size: 1.6rem;
    color: $color-13;
}