@mixin mq($width, $type: min) {
  @if $type == max {
    $width: $width - 1px;
  }
  @media only screen and (#{$type}-width: $width) {
    @content;
  }
}

@mixin mqs($max_width, $min_width) {
  $min_width: $min_width - 1px;
  @media only screen and (max-width: $max_width) and (min-width: $min_width) {
    @content;
  }
}
