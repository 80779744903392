@import "./components/sessionCard/sessionCard";
@import "./components/examCorrection/examCorrection";
@import "../../assets//styles/abstracts/colors";

.bulletin {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: start;
  gap: 8rem;
  overflow: scroll;
  padding: 1rem;
  width: 100%;
  height: calc(100vh - 220px);

  @include mq($contentHeader_breakpoint_small_screen, max) {
    flex-direction: column;
  }

  &-footer {
    display: flex;
    width: 100%;
    position: absolute;
    right: 8rem;
    bottom: 3rem;
    align-items: center;
    text-align: center;

    justify-content: flex-end;
    padding-bottom: 3rem;
    gap: 1rem;
    padding: 4rem 0px;

    @include mq($bulletin_exams_breakpoint, max) {
      flex-direction: column;
    }

    &_grade {
      color: #fff;
      background-color: $color-2;
      border-radius: 15px;
      font-weight: 500;
      padding: 10px;
      text-align: center;
      font-size: 1.8rem;

      @include mq($bulletin_exams_breakpoint, max) {
        width: 100%;
      }
    }

    &_range {
      padding: 10px;
      text-align: center;
      background: $color-23;
      border-radius: 15px;
      justify-content: center;
      font-weight: 500;
      font-size: 1.8rem;
      display: flex;
      align-items: center;
      color: #ffffff;

      @include mq($bulletin_exams_breakpoint, max) {
        width: 100%;
      }
    }
  }
}

.main_page-bulletin {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  padding-left: $page_left_padding;
  padding-right: $page_right_padding;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include mq($contentHeader_breakpoint_small_screen, max) {
    padding-left: $responsive_page_left_padding;
  }

  .contentHeader {
    position: sticky;
    left: 0;
  }
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.comingSoon {
  color: $color-11;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  padding-left: 3rem;
  padding-right: 3rem;

  &_img {
    margin: 6rem auto;
    margin-bottom: 0rem;
    max-width: 22rem;
  }
}

.exam-correction_desc-name {
  white-space: nowrap;
}