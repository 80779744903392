.login-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  background-color: $color-4;
  &_children {
    width: 70vw;
    overflow-y: auto;
    height: 100%;

    background-color: $color-0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 21px;
    &-rtl {
      border-bottom-left-radius: 10%;
      border-top-left-radius: 10%;
    }
    &-ltr {
      border-bottom-right-radius: 10%;
      border-top-right-radius: 10%;
    }
    @include mq($login_breakpoint, max) {
      width: 100%;
      border-radius: 0;
    }
  }
  &_illustration {
    width: 45vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-rtl {
    direction: rtl;
  }
  &-ltr {
    direction: ltr;
  }
}
.show-password-icon{
  transform: scale(1.2);
}