@import "../../assets/styles/abstracts/colors";
@import "./components/sectionTitle/sectionTitle";
@import "./components/EntityNumber/entityNumber";
@import "./components/OneExam/oneExam";
@import "./components/Exams/exams";
@import "./components/Bulletins/bulletins";
@import "./components/Statistique/Donut.scss";

.dashboard {
  width: 100%;
  padding: 1.5rem 2.3rem;

  @include mq(900px, max) {
    padding: 4.5rem 1rem;
  }

  &-content {
    display: flex;
    padding-bottom: 2rem;
    gap: 1rem;

    @include mq($dashboard_large_screen, max) {
      flex-wrap: wrap;
    }
  }

  &-rightSide {
    width: 100%;
    flex: 3.34;
  }

  &-leftSide {
    width: 100%;
  }

  h1 {
    color: $color-2;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 600;
  }
}

.apercu {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
}

.matiers {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.mySubjects {
  margin-top: 5.7rem;

  &-text {
    font-size: 1.8rem;
    color: $color-2;
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;

    @include mq(900px, max) {
      font-size: 1.15rem;
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5.7rem;

  article {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    justify-content: center;
    flex-wrap: wrap;
  }

  &-content {
    border-radius: 20px;
    border: 1px solid $color-8;
    background-color: $color-0;
    padding: 1rem;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0 !important;

    @include mq(400px, max) {
      align-items: flex-start;
      flex-direction: column;
    }

    &_RedItem {
      p {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
      }

      h3 {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
      }

      position: relative;

      &::before {
        content: "";
        position: absolute;
        border-radius: 0.8rem;
        background-color: $color-13;
        left: -20px;
        top: -12px;
        height: 7rem;
        width: 0.5rem;
      }
    }

    &_GreenItem {
      p {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
      }

      h3 {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
      }

      position: relative;

      &::before {
        content: "";
        position: absolute;
        border-radius: 0.8rem;
        background-color: $color-17;
        left: -20px;
        top: -12px;
        height: 7rem;
        width: 0.5rem;
      }
    }

    &_BlueItem {
      p {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
      }

      h3 {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
      }

      position: relative;

      &::before {
        content: "";
        position: absolute;
        border-radius: 0.8rem;
        background-color: $color-2;
        left: -20px;
        top: -12px;
        height: 7rem;
        width: 0.5rem;
      }
    }

    &_YellowItem {
      p {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
      }

      h3 {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
      }

      position: relative;

      &::before {
        content: "";
        position: absolute;
        border-radius: 0.8rem;
        background-color: $color-23;
        left: -20px;
        top: -12px;
        height: 7rem;
        width: 0.5rem;
      }
    }
  }

  &_BlueItem {
    p {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
    }

    h3 {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 500;
    }

    position: relative;

    &::before {
      content: "";
      position: absolute;
      border-radius: 0.8rem;
      background-color: $color-2;
      left: -20px;
      top: -12px;
      height: 7rem;
      width: 0.5rem;
    }
  }

  &_YellowItem {
    p {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
    }

    h3 {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 500;
    }

    position: relative;

    &::before {
      content: "";
      position: absolute;
      border-radius: 0.8rem;
      background-color: $color-23;
      left: -20px;
      top: -12px;
      height: 7rem;
      width: 0.5rem;
    }
  }
}

.welcome-body {
  width: 40% !important;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;

  .welcome-name {
    font-weight: 700;
    font-size: 40px;
    white-space: nowrap;
    color: #1b8392;
  }

  .welcome {
    font-weight: 400;
    white-space: nowrap;
    font-size: 40px;
    color: #1b8392;
  }

  .ant-modal-mask {
    background-color: rgba(255, 255, 255, 0.6) !important;
  }

  &-container {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
}

.welcome {
  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.60);
    filter: blur(4px);
    z-index: 9999;
  }

  &-body {
    position: absolute;
    z-index: 10000;
    top: 50%;
    border-radius: 8px;
    right: 50%;
    transform: translate(50%, -50%);
    padding: 4rem 6rem;
    box-shadow: 15px 21px 21px -11px rgba(131, 136, 197, 0.5);
    background-color: #F0F6F8;
  }
}

.custom-animation {
  transform: rotate3d(1, .5, .5, 180deg) scale(0.1);
  transition: transform ease-in .4s;
}

.custom-animation-visible {
  transition: opacity ease-in .4s;
}

.statsEmpty {
  margin-top: 1rem;
  border-radius: 20px;
  border: 1px solid #d0d5dd;
  background-color: #ffffff;
  padding: 2rem 1.5rem;
  overflow: auto;
  max-height: 37rem;
}

.apercuSection {
  margin-top: 7rem;
}

.emptyStats {
  margin-top: 5.7rem;
}

.SubjectEmptyStats {
  margin-top: 5.7rem;
}