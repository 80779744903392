.verification {
  &_desc {
    display: flex;
    align-items: center;
  }

  .character {
    border: none;
    font-size: 20px;
    border-radius: 8px;

    color: #272729;
    background-color: #f6f5fa;
    box-shadow: 0 2px 0 #e4e2f5;
  }

  .vi__wrapper {
    width: 100%;
    height: 4.5vw;
    align-self: center;

    @include mq($verification_input, max) {
      height: 7vw;
      width: 45vw;
    }

    @include mq($verification_input-2, max) {
      height: 12vw;
      width: 72vw;
    }
  }

  .container {
    max-width: 494px;
    height: 100%;
    width: 100%;

    justify-content: space-around;
    gap: 7px !important;

    .error {
      background-color: #FFF4F3;
      border: 2px solid #F04438;
    }
  }

  .character {
    border: 2px solid #d9d9d9;
    border-radius: 1.5rem;
    display: flex;
    font-size: 30px;

    @include mq($verification_input, max) {
      font-size: 5vw;
    }

    align-items: center;
    justify-content: center;
    color: #727272;
    background-color: white;
  }

  .character--inactive {
    font-size: 30px;

    @include mq($verification_input, max) {
      font-size: 5vw;
    }

    opacity: 0.6;
    color: rgba(217, 217, 217, 1);
    font-weight: 600;
  }

  .crypted_mail {
    color: $color-1;
    font-size: 1.8rem;
    line-height: 27px;
  }

  &_explain {
    color: $color-11;
    font-size: 1.7rem;
    line-height: 25.5px;
  }
}