.radio {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  &_group {
    margin-top: 20%;
    height: 40vh;
    margin-top: 8%;
    align-items: start;
    margin-left: 0.4rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  &_container {
    .Mui-checked {
      color: $color-6 !important;
    }
  }
}

.sidebar {
  width: 260px;
  background: $color-2;
  height: 100vh;
  & > div {
    width: 260px !important;
    height: 100% !important;
  }

  @include mq($sidebar-width-screen-breakpoint, max) {
    width: 100%;
    & > div {
      width: 100% !important;
    }
  }
  &_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  &-small {
    width: 75px;
    .radio {
      &_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 70px;
        gap: 1px;
      }

      &-letter {
        font-size: 1.5rem;
        font-weight: 400;
        margin-top: -3px;
        color: white;
      }
    }
  }
  &-responsive {
    border: none;
    z-index: 999999;
    height: 100vh;
    position: absolute;
    width: 100vw;
    background-color: hsla(0, 0%, 100%, 0.4);
  }
}
.divider {
  margin-left: 5%;
  width: 90%;
  height: 1px;
  background-color: $color-12;
  &-small {
    width: 75px;
    height: 1px;
    background-color: $color-12;
  }
}
