.auth-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.auth-checkbox label {
  position: relative;
  cursor: pointer;
}

.auth-checkbox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #727272;
  border-radius: 5px;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 11px;
}

.auth-checkbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: 8px;
  width: 4px;
  height: 10px;
  border: 0.5px solid #727272;

  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
