.exams{
  border-radius: 20px;
  border: 1px solid $color-8;
  background-color: $color-0;
  padding: 2rem 1.5rem;
  overflow: auto;
  max-height: 37rem;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-2;
  }

  &::-webkit-scrollbar-track {
    background-color: #f2f2f2;
  }

  @include mq(700, max) {
    padding: 1rem 1.5rem;
  }

  &-select {
    display: flex;
    padding: 1rem 3rem;
    justify-content: flex-end;
    gap: 3rem;
  }
}

.emptyExam {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  &_imgContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq(1200px, min) {
      width: 62rem;
    }
  }

  &_img {
    margin: 1rem;

    @include mq(530px, max) {
      width: 70%;
    }
  }

  p {
    color: $color-2;
    text-align: center;
    font-size: 2rem;
    font-weight: 400;

    @include mq(530px, max) {
      font-size: 3.5vw;
    }
  }
}
