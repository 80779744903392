.subject {
  width: 100%;
  border-radius: 1.2rem;
  padding: 1.4rem;
  position: relative;
  flex: 3.33;
  margin-top: .7rem;
  &-redirect {
    background-color: $color-2;
    padding: 0.5rem 1.4rem;
    color: $color-0;
    text-align: center;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
    border-radius: 0.8rem;
    text-decoration: none !important;
    text-transform: capitalize;
  }

  &-mentor {
    color: $color-10;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    word-break: break-all;
  }

  &-description {
    color: $color-10;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
  }

  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      color: $color-10;
      font-family: Poppins;
      font-size: 12.8px;
      font-style: normal;
      font-weight: 400;
    }
  }

  &-content {
    padding: 4rem 4rem;

    &_title {
      color: $color-1;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
    }
  }

  &-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;

    &_img {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      object-fit: cover;
    }

    &_corn {
      position: absolute;
      right: 0;
      top: 0;
    }

    &_info {
      color: $color-2;
      margin-top: 1rem;
    }
  }

  &-name {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
  }

  &-coefficient {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
  }

  &-footer {
    padding: 0 2rem 2rem 2rem;
    &_avatar {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      object-fit: cover;
    }

    &_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
    }

    &_left {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}

.progressBar {
  margin-top: 1rem;
  background-color: $color-11;
  width: 100%;
  border-radius: 2rem;
  height: 0.7rem;
  transition: all 1s ease;

  &-value {
    background-color: $color-10;
    height: 100%;
    border-radius: 2rem;
    transition: all 1s ease;
  }
}
