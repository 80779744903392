.modal {
  min-width: 390px;
  @include mq(415px,max){
    min-width: none;
  }
  filter: drop-shadow(5px 21px 21px rgba(131, 136, 197, 0.50));
  &-background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    border: none;
    position: absolute;
    z-index: 99999;

    background-color:#FFFFFFAB;
  }
  background-color: $color-18;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  width: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  justify-content: start;
  min-height: 40rem;
  max-width: 55.9rem;
  border-radius: 25px;
  position: relative;
  gap: 30px;
  &-header {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &-prefix {
    position: absolute;
    left: -80px;
    top: -80px;
  }
}
