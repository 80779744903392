.contentHeader {
  @include mq($header_breakpoint, max) {
    flex-direction: column;
  }
  &-children {
    @include mq($header_breakpoint, max) {
      width: 100%;
    }
  }
}
