.AllBulletins {
  border-radius: 20px;
  border: 1px solid $color-8;
  background-color: $color-0;
  padding: 2rem 1.5rem;
  overflow: auto;
  margin-bottom: 4rem;

  @include mq(600, max) {
    padding: 1.5rem 1rem;
  }
}

.bulletins {
  &-header {
    display: flex;
    gap: 2rem;
    align-items: center;
    height: fit-content;

    &_info {
      h3 {
        color: $color-2;
        white-space: nowrap;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;

        @include mq(600, max) {
          font-size: 1.5rem;
        }
      }

      p {
        color: $color-2;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
      }
    }

    &_img {
      width: 6rem;
      height: 6rem;
      border-radius: 50%;

      @include mq(600, max) {
        width: 4rem;
        height: 4rem;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    padding: 2rem 2rem;
    border-radius: 2rem;
    background-color: $color-4;
    align-items: center;
    margin: 0.7rem;
    gap: 0.5rem;

    p {
      color: $color-10;
      font-size: 1.7rem;
      font-style: normal;
      font-weight: 500;
      flex: 0.33;
      @include mq(600, max) {
        font-size: 1.15rem;
      }
    }

    h4 {
      color: $color-2;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      white-space: nowrap;

      @include mq(600px, max) {
        font-size: 1rem;
      }
    }

    span {
      border-radius: 2.5rem;
      padding: 1rem;
      text-align: center;
      white-space: nowrap;

      @include mq(600px, max) {
        font-size: 1.4rem;
        padding: 0.5rem;
        border-radius: 1rem;
      }
    }
  }

  &-content {
    overflow: auto;
  }

  &-element {
    display: flex;
    justify-content: space-between;
    padding: 2rem 2rem;
    border-radius: 2rem;
    align-items: center;
    border-bottom: 1px solid $color-4;
    min-width: 46rem;

    @include mq(600px, max) {
      min-width: 36rem;
    }

    p {
      color: $color-10;
      font-size: 1.7rem;
      font-style: normal;
      font-weight: 500;
      flex: 0.33;
      @include mq(600, max) {
        font-size: 1.2rem;
      }
    }

    h4 {
      color: $color-2;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;

      @include mq(600px, max) {
        font-size: 1.2rem;
      }
    }

    span {
      border-radius: 2.5rem;
      padding: 1rem;
      text-align: center;
      white-space: nowrap;

      @include mq(600px, max) {
        font-size: 1.4rem;
        padding: 0.5rem;
        border-radius: 1rem;
      }
    }
  }
}
