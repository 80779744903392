.exam {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  border-bottom: 1.5px solid $color-4;
  padding-bottom: 1rem;
  padding-top: 1rem;
  min-width: 62rem;
  @include mq(600, max) {
    min-width: 52rem;
  }
  &:last-child{
    border: none;
  }
  &_img {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    flex: 0;
    @include mq(600, max) {
      width: 4.5rem;
      height: 4.5rem;
    }
  }
  &_info {
    flex: 1;
    h4 {
      color: #000;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 500;
      white-space: nowrap;

      @include mq(600, max) {
        font-size: 1.15rem;
      }
    }

    p {
      color: #000;
      font-size: 1.26rem;
      font-style: normal;
      font-weight: 400;
      white-space: nowrap;

      @include mq(600, max) {
        font-size: 1.15rem;
      }
    }
  }
  &-name {
    flex: 1;
    color: #000;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;

    @include mq(600, max) {
      font-size: 1.15rem;
    }
  }
  &-name {
    color: #000;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;

    @include mq(600, max) {
      font-size: 1.15rem;
    }
  }
  &-grade {
    flex: 1;
    border-radius: 2.5rem;
    padding: 1rem;
    text-align: center;
    white-space: nowrap;

    @include mq(600, max) {
      font-size: 1.15rem;
    }
  }
  &-rang {
    flex: 0;
    color: $color-2;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    @include mq(600, max) {
      font-size: 1.15rem;
    }
  }
}
