.question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  gap: 1rem;
  border-radius: 12px;
  border: 1px solid $color-9;
  &_desc {
    gap: 1rem;
    display: flex;
    align-items: center;
  }
  @include mq($correction_breakpoint, max) {
    flex-direction: column;
  }
  .grade {
    font-weight: 500;
    font-size: 2rem;
    border-radius: 5px;
    display: flex;
    gap: 3px;
  }
  &-big {
    width: 96%;
  }
  &-medium {
    width: 92%;
  }
  &-small {
    width: 88%;
  }
}
