.session-card {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;

  &:last-child {
    padding-right: 3rem;
  }

  &_title {
    padding: 9px 10px 9px 50px;

    background: $color-4;
    border-radius: 10px;

    font-weight: 600;
    font-size: 1.8rem;
    white-space: nowrap;
    color: $color-2;
  }

  &_exams {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    border-radius: 10px;
    padding: 2rem 3rem;
    box-shadow: -2px 2px 15px 0px #e3e3e5;

    @include mq($bulletin_exams_breakpoint, max) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
    }
  }
}

.no-data-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 3rem;

  &_text {
    color: $color-11;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    padding: 0 8rem;
    padding-bottom: 3rem;
  }
}