$color-0: #ffffff;
$color-1: #102528;
$color-2: #1b8392;
$color-4: #f0f6f8;
$color-5: #f3f6f6;
$color-6: #fbb800;
$color-7: #ea366e;
$color-8: #d0d5dd;
$color-9: #d9d9d9;
$color-10: #4c4c4d;
$color-11: #727272;
$color-12: #99c6d3;
$color-13: #f04438;
$color-14: #fff4f3;
$color-15: #959595;
$color-16: #b5b5b5;
$color-17: #12b76a;
$color-18: #f4fff9;
$color-19: #d8ecf3;
$color-20: #d1fadf;
$color-21: #fff4d3;
$color-22: #fee4e2;
$color-23: #f69d16;
$color-24: #99c6d3;
$color-25: lightgray;

.background-color-0 {
  background-color: $color-0;
}

.background-color-1 {
  background-color: $color-1;
}

.background-color-2 {
  background-color: $color-2;
}

.background-color-4 {
  background-color: $color-4;
}

.background-color-5 {
  background-color: $color-5;
}

.background-color-6 {
  background-color: $color-4;
}

.background-color-7 {
  background-color: $color-7;
}

.background-color-8 {
  background-color: $color-8;
}

.background-color-9 {
  background-color: $color-9;
}

.background-color-10 {
  background-color: $color-10;
}

.background-color-11 {
  background-color: $color-11;
}

.background-color-12 {
  background-color: $color-12;
}

.background-color-13 {
  background-color: $color-13;
}

.background-color-14 {
  background-color: $color-14;
}

.background-color-15 {
  background-color: $color-15;
}

.background-color-16 {
  background-color: $color-16;
}

.background-color-17 {
  background-color: $color-17;
}

.background-color-18 {
  background-color: $color-18;
}

.background-color-19 {
  background-color: $color-19;
}

.background-color-20 {
  background-color: $color-20;
}

.background-color-21 {
  background-color: $color-21;
}

.background-color-22 {
  background-color: $color-22;
}

.background-color-23 {
  background-color: $color-23;
}

.background-color-24 {
  background-color: $color-18;
}

.text-color-0 {
  color: $color-0;
}

.text-color-1 {
  color: $color-1;
}

.text-color-2 {
  color: $color-2;
}

.text-color-4 {
  color: $color-4;
}

.text-color-5 {
  color: $color-5;
}

.text-color-6 {
  color: $color-6;
}

.text-color-7 {
  color: $color-7;
}

.text-color-8 {
  color: $color-8;
}

.text-color-9 {
  color: $color-9;
}

.text-color-10 {
  color: $color-10;
}

.text-color-11 {
  color: $color-11;
}

.text-color-12 {
  color: $color-12;
}

.text-color-13 {
  color: $color-13;
}

.text-color-14 {
  color: $color-14;
}

.text-color-15 {
  color: $color-15;
}

.text-color-16 {
  color: $color-16;
}

.text-color-17 {
  color: $color-17;
}

.text-color-18 {
  color: $color-18;
}

.text-color-19 {
  color: $color-19;
}

.text-color-20 {
  color: $color-20;
}

.text-color-21 {
  color: $color-21;
}

.text-color-22 {
  color: $color-22;
}

.text-color-23 {
  color: $color-23;
}

.text-color-24 {
  color: $color-24;
}

.border-color-1 {
  border-color: $color-1;
}

.border-color-2 {
  border-color: $color-2;
}

.border-color-4 {
  border-color: $color-4;
}

.border-color-5 {
  border-color: $color-5;
}

.border-color-6 {
  border-color: $color-6;
}

.border-color-7 {
  border-color: $color-7;
}

.border-color-8 {
  border-color: $color-8;
}

.border-color-9 {
  border-color: $color-9;
}

.border-color-10 {
  border-color: $color-10;
}

.border-color-11 {
  border-color: $color-11;
}

.border-color-12 {
  border-color: $color-12;
}

.border-color-13 {
  border-color: $color-13;
}

.border-color-14 {
  border-color: $color-14;
}

.border-color-15 {
  border-color: $color-15;
}

.border-color-16 {
  border-color: $color-16;
}

.border-color-17 {
  border-color: $color-17;
}

.border-color-18 {
  border-color: $color-18;
}

.border-color-19 {
  border-color: $color-19;
}

.border-color-20 {
  border-color: $color-20;
}

.border-color-21 {
  border-color: $color-21;
}

.border-color-22 {
  border-color: $color-22;
}

.border-color-23 {
  border-color: $color-23;
}

.border-color-24 {
  border-color: $color-24;
}
