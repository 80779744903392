.signup-form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 80%;
  direction: initial;
  &_title {
    font-size: 4rem;
    font-weight: 600;
    text-align: center;
    line-height: 68px;
    color: $color-2;
  }
}
.sign-up-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  @include mq($sign_up_wrap, max) {
    flex-direction: column;
  }
}
.sign-up-row{
.input{
  height: 115px;
  position: relative;
  .MuiFormHelperText-root{
    position: absolute;
    bottom: -25px;
  height: 20px;
  font-size: 1.6rem;
}
}
}
