@import "./components/grade/grade";
@import "./components/exercice/exercice";
@import "./components/question/question";

.speed-dial {
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  position: absolute;
  right: 2rem;
  bottom: 15px;
  right: 30px;

  &:hover {
    transform: translateY(-1px);
  }
}

.correction_page {

  .excercise {
    margin-bottom: 6rem;
  }
}

.remark {
  color: #727272;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: right;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

.correction-ar {
  direction: rtl;

  .grade {
    direction: ltr;
  }
}


.correction {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-right: 3rem;
}