.updated {
  &-password {
    box-shadow: 0px 0px 15px 0px rgba(210, 209, 209, 0.6);
    border-radius: 1.5rem;
    padding: 2rem 2.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3.5rem;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    flex: 1.2;
    &_title {
      color: $color-2;
      font-size: 2rem;
      font-weight: 600;
      width: 100%;
      text-align: start;
    }

    &_img {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_button {
      padding: 1rem 1.5rem;
      text-align: center;
      width: 100%;
      border-radius: 1rem;
      border: 2px solid $color-13;
      color: $color-13;
      background-color: $color-0;
      cursor: pointer;
      font-weight: 600;
    }
  }
}

.password {
  &-modal {
    width: 56.2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    box-shadow: 0px 0px 15px 0px rgba(210, 209, 209, 0.1);
    border-radius: 1.2rem;
    padding: 3rem 4rem;
    position: absolute;
    background-color: $color-0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    @include mq(630, max) {
      width: 90vw;    
    }
    &_btns {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
    }

    &_form {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
    }

    &_header {
      display: flex;
      justify-content: space-between;
      gap: 3rem;

      p {
        color: $color-2;
        font-size: 2rem;
        font-weight: 500;
      }

      img {
        cursor: pointer;
      }
    }
  }
}

.password-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
