.ant-dropdown {
  z-index: 10000;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details-event {
  padding: 16px 24px 20px 24px !important;
  display: flex;
  flex-direction: column;
  gap: 2.2rem;

  &-date {
    overflow-wrap: break-word;
    font-size: 1.3rem;
    font-weight: 500;
    color: $color-11
  }

  &-det {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: $color-2
  }
}

.dialog-title {
  min-width: 46rem;
}