.EntityNumber {
  border-radius: 2rem;
  padding: 2rem 1rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;

  img {
    background-color: $color-0;
    padding: 1rem;
    border-radius: 1rem;
  }
}
